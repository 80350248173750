.OnboardingMessage {
	padding: var(--spacing-2) var(--spacing-3);
	border-radius: var(--border-radius-md);
	border: var(--color-grey-3) 1px solid;
	background: var(--color-white);
	width: 25rem;
	height: 30rem;
	overflow-y: auto;
}

.Frame {
	border-radius: var(--radius-1);
	border: none;
	width: 100%;
	height: 20rem;
}
