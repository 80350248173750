.UserMenu {
	padding: var(--spacing-sm);
}

.DropdownContent {
	padding: var(--spacing-sm);
}

.Logo {
	width: 3.3rem;
	height: 2.8rem;
}
