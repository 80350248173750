.Banner {
	display: flex;
	justify-content: center;
	padding: var(--container-padding-sm) var(--container-padding-lg);
	background-color: var(--color-blurple);
}

.BannerColorBlurple {
	color: var(--color-white);
	background-color: var(--color-blurple);
}

.BannerColorRed {
	color: var(--color-white);
	background-color: var(--color-red-7);
}

.BannerColorGreen {
	color: var(--color-white);
	background-color: var(--color-green-7);
}

.BannerContent {
	max-width: 70rem;
	display: flex;
	place-content: center;
	align-items: baseline;
	align-self: center;
	text-align: center;
}

.CloseButtonContainer {
	margin: 0 0.5rem;
	align-self: center;
}
