.BottomNav {
	padding: 1.15rem 2rem;
	height: 5.652rem;
	border-top: 1px solid var(--color-grey-3);
	background-color: var(--color-white);
}

.DisabledLink {
	color: var(--color-grey-3);
}
